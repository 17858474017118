import React from "react";
import {useState} from "react";
import axios from "axios";
import querystring from "querystring";

import logo from "../images/milan-hr.svg";

function Feedback({email, setPageNum}) {
  const [recommendationNum, setRecommendationNum] = useState(null);
  const [bouncyChecks, setBouncyChecks] = useState({
    price: {value: false, text: "Price/Budget"},
    time: {value: false, text: "Convenience of treatment times"},
    year: {value: false, text: "Time of year"},
    pain: {value: false, text: "pain"},
    other: {value: false, text: "No other areas apply for laser hair removal"},
    spouse: {value: false, text: "Other"},
    purchase: {value: false, text: "nothing is stopping me, i am purchasing additional areas today!"},
  });
  const [electronicSign, setElectronicSign] = useState(false);

  const [recommendationNumError, setRecommendationNumError] = useState(null);
  const [bouncyChecksError, setBouncyChecksError] = useState(null);
  const [electronicSignError, setElectronicSignError] = useState(null);
  let numError = false;
  let bouncyError = false;
  let eSignError = false;

  //handles bouncyCheck change
  const handleBouncyCheckUpdate = (objValue) => {
    // console.log('handleBouncyCheckUpdate initiated')
    setBouncyChecks((prevBouncyChecks) => ({
      ...prevBouncyChecks,
      [objValue]: {
        ...prevBouncyChecks[objValue],
        value: !prevBouncyChecks[objValue].value,
      },
    }));
  };

  //handles the submit button
  //when the user clicks submit button, we go through data validation before we submit first
  //then it posts to the salesforce to upload the data
  const handleSubmit = () => {
    console.log("entered handleSubmit function");

    //if recommendationNum is null, the user needs to choose the recommendation number
    if (recommendationNum === null) {
      setRecommendationNumError(true);
      numError = true;
    } else {
      setRecommendationNumError(false);
      numError = false;
    }

    // checking to see if at least one of the checkmarks are checked to true.
    const allFalse = Object.values(bouncyChecks).every((check) => check.value === false);
    // allFalse ? setBouncyChecksError(true): setBouncyChecksError(false);
    if (allFalse) {
      setBouncyChecksError(true);
      bouncyError = true;
    } else {
      setBouncyChecksError(false);
      bouncyError = false;
    }

    //the user has to agree to the electronic sign checkbox before submitting to salesforce
    electronicSign ? setElectronicSignError(false) : setElectronicSignError(true);
    if (electronicSign) {
      setElectronicSignError(false);
      eSignError = false;
    } else {
      setElectronicSignError(true);
      eSignError = true;
    }

    //then we can make the post call to submit the information for this email account in salesforce
    if (numError === false && bouncyError === false && eSignError === false) {
      // setIsLoading(true);
      console.log("all the requirements are met to submit! now submitting...");
      let objections = "";
      Object.values(bouncyChecks).map((item) => {
        if (item.value) {
          objections.length === 0 ? (objections = item.text) : (objections = objections + "; " + item.text);
        }
        return null;
      });
      const apiObject = {email, objections, netPromoterScore: recommendationNum};
      axios
        // .post("https://cors-milanlaser.herokuapp.com/https://milan-laser.secure.force.com/cssinbound/services/apexrest/cssinbound", querystring.stringify(apiObject))
        .post("https://milan-cors-2023-9de078d0fd3b.herokuapp.com/https://milanlaser.my.salesforce-sites.com/cssinbound/services/apexrest/cssinbound", querystring.stringify(apiObject))
        .then((res) => {
          setPageNum(2);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      console.log("failed to meet the requirements... :(");
    }
  }; //end of handleSubmit

  //rating buttons
  const buttons = [];
  for (let i = 0; i <= 10; i++) {
    const isSelected = recommendationNum === i;
    buttons.push(
      <button
        className={`mx-1 milan-btn milan-secondary-btn reccomendButton p-subhead ${isSelected ? "milan-secondary-btn-selected" : ""}`}
        // style={{fontSize: '1.5rem'}}
        key={i}
        onClick={() => setRecommendationNum(i)}
      >
        <strong>{i}</strong>
      </button>
    );
  }

  function FeedbackOptions(option) {
    return (
      // <div className="col-4 text-center p-2">
      <div className="col-6 col-lg-4 text-center p-2">
        <button
          className={`milan-btn milan-white-btn feedbackBtn d-flex justify-content-center align-items-center ${bouncyChecks[option].value ? "milan-white-btn-selected" : ""}`}
          // style={{height: "88px"}}
          onClick={() => {
            handleBouncyCheckUpdate(option);
          }}
        >
          {bouncyChecks[option].text}
        </button>
      </div>
    );
  }

  return (
    <div id="feedback" className="container">
      <div className="text-center py-3">
        <img src={logo} alt="Milan Laser Hair Removal Logo" style={{maxWidth: "250px"}} />
        <h1 className="smalltitle pt-4 milan-text-primary" style={{fontWeight: '300'}}>CLIENT SATISFACTION SURVEY</h1>
      </div>
      <div className="text-center">
        <h2 className="p-subhead">How likely are you to recommend Milan Laser to a friend or colleague?</h2>
        <div style={{height: "18px"}}>
          {recommendationNumError && (
            <p className="milan-text-red mb-0 milan-fp">
              <strong>Please select an option</strong>
            </p>
          )}
        </div>
      </div>
      {/* show the rating numbers */}
      <div className="text-center pt-2">{buttons}</div>
      <div className="row justify-content-between pt-3">
        <div className="col text-start milan-fp">
          <p>Not Likely</p>
        </div>
        <div className="col text-center milan-fp">
          <p>Somewhat Likely</p>
        </div>
        <div className="col text-end milan-fp">
          <p>Very Likely</p>
        </div>
      </div>
      {/* <p>you've chosen: {recommendationNum}</p> */}

      <div className="milan-bg-light-blue container py-4 milan-border-radius">
        <div className="text-center pb-2">
          <h2 className="p-subhead">What would stop you from getting other areas treated? Check all that apply</h2>
          <div style={{height: "18px"}}>
            {bouncyChecksError && (
              <p className="milan-text-red milan-fp">
                <strong>Please select at least one option. Check all that apply.</strong>
              </p>
            )}
          </div>
        </div>
        <div className="row justify-content-md-center">
          {FeedbackOptions("price")}
          {FeedbackOptions("time")}
          {FeedbackOptions("year")}
          {FeedbackOptions("pain")}
          {FeedbackOptions("other")}
          {FeedbackOptions("spouse")}
          {FeedbackOptions("purchase")}
        </div>
      </div>

      <div className="d-flex justify-content-center py-3">
        <div className="custom-checkbox">
          <label className="area_label">
            {electronicSignError && (
              <span className="milan-text-red">
                <strong>Electronic Signature is required </strong>
              </span>
            )}
            By checking this box you are electronically signing this survey.
            <input
              type="checkbox"
              onClick={() => {
                setElectronicSign(!electronicSign);
              }}
            />
            <span className="checkmark" />
          </label>
        </div>
      </div>
      <div className="text-center">
        <button
          className="milan-btn milan-cta-btn"
          style={{width: "300px"}}
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit Survey
        </button>
      </div>
    </div>
  );
}

export default Feedback;
