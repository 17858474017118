import React from "react";
import {useState} from "react";
import axios from "axios";
import querystring from "querystring";
import logo from "../images/milan-hr.svg";

function EmailVerify({setPageNum, email, setEmail}) {
  const [errorMessage, setErrorMessage] = useState("");

  const checkEmail = (e) => {
    //prevents from submitting the form so it doesn't refresh
    e.preventDefault();
    if (email === "") {
      setErrorMessage("Email field cannot be empty");
      // } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    } else if (!/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setErrorMessage("Email address is invalid");
    } else {
      const emailObj = {email};
      axios
        // .post("https://cors-milanlaser.herokuapp.com/https://milan-laser.secure.force.com/clientcheckemail/services/apexrest/checkclientemail", querystring.stringify(emailObj))
        .post("https://milan-cors-2023-9de078d0fd3b.herokuapp.com/https://milanlaser.my.salesforce-sites.com/clientcheckemail/services/apexrest/checkclientemail", querystring.stringify(emailObj))
        .then((res) => {
          console.log(res.data);
          if (res.data === "yes") {
            setErrorMessage("email is in salesforce!!! YAY!");
            setPageNum(1);
          } else {
            setErrorMessage("Email does not match in Salesforce. Please check email and try again.");
          }
        })
        .catch((e) => {
          console.log(e);
        })
    }
  };

  return (
    <>
      <div id="check-email" className="d-flex align-items-center" style={{height: "100vh"}}>
        <div className="row w-100">
          <div className="offset-5 col-7 text-center">
            <img src={logo} alt="Milan Laser Hair Removal Logo" style={{maxWidth: "340px"}} />
            <h1 className="smalltitle py-5 milan-text-primary" style={{fontWeight: '300'}}>CLIENT SATISFACTION SURVEY</h1>
            <div className="d-flex justify-content-center">
              <div className="mb-3">
                <input
                  type="email"
                  autocomplete="off"
                  className={`form-control d-inline ${errorMessage !== '' ? 'errorBorderColor' : ''}`}
                  id="InputEmail"
                  style={{width: "370px"}}
                  aria-describedby="emailHelp"
                  placeholder="Client's Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                {errorMessage === "" ? <div className="form-text pt-3">Please make sure you use the email that's in Salesforce.</div>: <></>}
                {errorMessage !== "" ? <div className="form-text pt-3 milan-text-red">{errorMessage}</div>: <></>}
              </div>
            </div>
            <button
              className="milan-btn milan-cta-btn"
              style={{width: "300px"}}
              type="submit"
              onClick={(e) => {
                checkEmail(e);
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailVerify;
