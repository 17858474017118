import React from "react";
import logo from "../images/milan-hr.svg";

//this will be a section that will show the 60% off one body area after submitting
function Result() {
  return (
    <>
      <div id="result" className="d-flex align-items-center" style={{height: "100vh"}}>
        <div className="row w-100">
          <div className="offset-5 col-7 text-center">
            <img src={logo} alt="Milan Laser Hair Removal Logo" style={{maxWidth: "340px"}} />
            <h1 className="smalltitle py-5 milan-text-primary" style={{fontWeight: '300'}}>CLIENT SATISFACTION SURVEY</h1>
              <h3 className="milan-text-tur subpointlg"><strong>THANK YOU!</strong></h3>
              <p className="p-subhead pt-3" style={{lineHeight: '2.1rem'}}>Enjoy 60% off one body area today.<br/>Talk to your provider to learn more.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Result;
