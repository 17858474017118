import {useState} from "react";

import EmailVerify from "./components/clientSurvey/EmailVerify";
import Feedback from "./components/clientSurvey/Feedback";
import Result from "./components/clientSurvey/Result";

import "./App.css";

function App() {
  const [pageNum, setPageNum] = useState(0);
  const [email, setEmail] = useState("")

  return (
    <div>
      {pageNum === 0 ? <EmailVerify setPageNum={setPageNum} email={email} setEmail={setEmail} /> : <></>}
      {pageNum === 1 ? <Feedback setPageNum={setPageNum} email={email}/> : <></>}
      {pageNum === 2 ? <Result/> : <></>}
    </div>
  );
}

export default App;
